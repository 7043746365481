import "./src/scss/main.scss"
import React from 'react'
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3'
import Cookies from 'universal-cookie';
import {UTM_CAMPAIGN, UTM_SOURCE} from "./src/helpers/activeCampaignCustomFieldsBuilder";

const reCaptchaKey = process.env.GATSBY_RECAPTCHA_KEY;
const UTM_EXP_MINUTES = Number(process.env.GATSBY_UTM_EXP_MINUTES);
const cookies = new Cookies();

const setCookie = ({key, value}) => {
  const expires = new Date();
  expires.setMinutes(expires.getMinutes() + UTM_EXP_MINUTES);
  cookies.set(key, value, { path: '/', expires } );
}
export const wrapRootElement = ({element}) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
      {element}
    </GoogleReCaptchaProvider>
  )
}

export const onRouteUpdate = ({ location, prevLocation, ...rest }) => {
  const params = new URLSearchParams(location.search);
  const utm_source = params.get(UTM_SOURCE);
  const utm_campaign = params.get(UTM_CAMPAIGN);
  if( utm_source) setCookie({key: UTM_SOURCE , value: utm_source})
  if( utm_campaign) setCookie({key: UTM_CAMPAIGN , value: utm_campaign})
}
