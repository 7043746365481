import Cookies from 'universal-cookie';

const cookies = new Cookies();
export const UTM_SOURCE = 'utm_source';
export const UTM_CAMPAIGN = 'utm_campaign';

const resolveUTMValues = (params) => {
  const utm_source = params.get(UTM_SOURCE) ||  cookies.get(UTM_SOURCE);
  const utm_campaign = params.get(UTM_CAMPAIGN) || cookies.get(UTM_CAMPAIGN);
  return {
    utm_source,
    utm_campaign
  }

}

export const buildUTMCustomFields = (queryString) => {
  const params = new URLSearchParams(queryString);
  const utmValues = resolveUTMValues(params);
  const utm_source = utmValues.utm_source || 'none';
  const utm_campaign = utmValues.utm_campaign || 'none';
  return {utm_source, utm_campaign}
}
